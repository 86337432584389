var mbkpt = '991';

jQuery(document).ready(function ($) { 
  /* SlimMenu Prep */
  $('#navigation').slimmenu({
    resizeWidth: mbkpt,
    collapserTitle: '',
    animSpeed: 0,
    easingEffect: null,
    indentChildren: false,
    childrenIndenter: '&nbsp;',
    expandIcon: '',
    collapseIcon: ''
  });
});

// Run MobileInit on ready, as well as on resize
$(mobileInit);
$(window).on('resize',mobileInit);

function mobileInit() {
  // Moves items on Navigation collapse to mobile
  $('[data-move-to]').each(function(){
    var breakpoint = mbkpt;
    var moveto      = $(this).data('move-to');
    var method      = $(this).data('move-method');
    var ww          = $(window).width();
    var movefrom    = this.id;

    if(ww < breakpoint){
      $(this).wrap($('<span>').attr('data-ref', movefrom));
      
      if(method == 'prepend'){
        $(moveto).prepend($(this));
      } else {
        $(moveto).append($(this));
      }

      $(this).attr('data-move-from', movefrom).removeAttr('data-move-to').show();
    }
  });
  $('[data-move-from]').each(function(){
    var breakpoint;
    var movefrom    = $(this).data('move-from');
    var moveto      = $(this).parent().attr('id');
    var ww          = $(window).width();

    if($(this).data('move-breakpoint') != null){
      breakpoint = $(this).data('move-breakpoint');
    } else {
      breakpoint = mbkpt;
    }

    if(ww > breakpoint){
      $('span[data-ref="' + movefrom + '"]').replaceWith($('#' + movefrom));
      $(this).attr('data-move-to', '#' + moveto).removeAttr('data-move-from');
    }
  });
}